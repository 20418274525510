<script setup>
import { NodeViewWrapper } from '@tiptap/vue-3';

const props = defineProps({
  node: {
    type: Object,
    required: true,
  },
  getPos: {
    type: Function,
    required: true,
  },
});

function handleMousedown(event) {
  event.preventDefault();
  event.stopPropagation();

  const pos = props.getPos();
  const customEvent = new CustomEvent('formula-chip-click', {
    detail: {
      pos,
      node: props.node.toJSON(),
    },
    bubbles: true,
  });
  event.currentTarget.dispatchEvent(customEvent);
}
</script>

<template>
  <NodeViewWrapper>
    <span
      class="formula-chip"
      :style="{ backgroundColor: node.attrs.color || '#E6F0FF' }"
      contenteditable="false"
      @mousedown="handleMousedown"
    >
      <LscIcon v-if="node.attrs.icon" :icon="node.attrs.icon" class="mr-1" />
      {{ node.attrs.value }}
    </span>
  </NodeViewWrapper>
</template>

<style scoped>
.formula-chip {
  display: inline-flex;
  align-items: center;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  margin: 0 0.125rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  cursor: pointer;
  user-select: none;
}
</style>
