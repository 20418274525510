<script setup>
import { useI18n } from '@/util';
import CustomfieldAddOrEditDialogFieldTypes from '../CustomfieldAddOrEditDialogFieldTypes.vue';
import CustomfieldAddOrEditAdvancedFormula from './CustomfieldAddOrEditAdvancedFormula.vue';
import CustomfieldAddOrEditAdvancedFormulaOptions from './CustomfieldAddOrEditAdvancedFormulaOptions.vue';

defineProps({
  customfield: {
    type: Object,
    required: true,
  },
  customfieldType: {
    type: String,
    required: true,
  },
  isEditing: {
    type: Boolean,
    required: true,
  },
  entity: {
    type: String,
    required: true,
  },
  canAddFormula: {
    type: Boolean,
    required: true,
  },
});

const emit = defineEmits(['update:customfield', 'update:customfieldType']);

const formulaRef = ref(null);

const { t } = useI18n();
</script>

<template>
  <div class="flex">
    <div class="w-80 border-r border-separator">
      <CustomfieldAddOrEditAdvancedFormulaOptions :columns="formulaRef?.columns" :editor="formulaRef?.editor" />
    </div>

    <div class="w-96 flex-1 p-6">
      <div class="flex flex-col gap-x-6 gap-y-4">
        <div class="flex gap-3">
          <VTextField
            :modelValue="customfield.name"
            :label="t('Field title')"
            density="compact"
            autofocus
            required
            @update:modelValue="emit('update:customfield', { ...customfield, name: $event })"
          />

          <CustomfieldAddOrEditDialogFieldTypes
            :modelValue="customfieldType"
            :disabled="isEditing"
            :canAddFormula="canAddFormula"
            @update:modelValue="emit('update:customfieldType', $event)"
          />
        </div>

        <CustomfieldAddOrEditAdvancedFormula
          ref="formulaRef"
          :modelValue="customfield.formula"
          :editing="isEditing"
          :entity="entity"
          @update:modelValue="emit('update:customfield', { ...customfield, formula: $event })"
        />
      </div>
    </div>
  </div>
</template>
