import { Node } from '@tiptap/core';
import { VueNodeViewRenderer } from '@tiptap/vue-3';
import CustomfieldAddOrEditAdvancedFormulaChip from './CustomfieldAddOrEditAdvancedFormulaChip.vue';

// Formula functions and their icons
export const formulaFunctions = ['AND', 'COUNT', 'DAY', 'IF', 'MAX', 'MEDIAN', 'NOT', 'OR', 'ROUND', 'SUM'];

/**
 * Parses a formula string into Tiptap JSON structure for editing/copying formula
 * @param {string} formulaString - The string representing a formula (e.g. `"SUM" ("Profit" - "Total cost")`).
 * @param {Object<string, string>} knownColumns - An object mapping column names to their icon (e.g. { 'Profit': 'lsi-time', ... }).
 * @returns {Object|null} A Tiptap JSON object representing the document with chips and text nodes, or null if formulaString is falsy.
 */
export function parseFormulaStringToTiptapJson(formulaString, knownColumns) {
  if (!formulaString) return null;

  const content = [];
  let currentText = '';
  let inQuotes = false;
  let quoteChar = null;
  let quoteStart = 0;

  const chars = [...formulaString];
  chars.forEach((char, i) => {
    if (char === '"' || char === "'") {
      if (inQuotes && char === quoteChar) {
        // End of quoted text
        const quotedText = formulaString.slice(quoteStart, i);
        if (currentText) {
          content.push({
            type: 'text',
            text: currentText,
          });
          currentText = '';
        }

        // Determine if it's a function or column
        const isFunction = formulaFunctions.includes(quotedText.toUpperCase());
        content.push({
          type: 'formulaChip',
          attrs: {
            type: isFunction ? 'function' : 'column',
            value: quotedText,
            icon: isFunction ? 'lsi-customfield-formula' : knownColumns[quotedText],
            color: isFunction ? undefined : '#E6F0FF',
          },
        });
        inQuotes = false;
        quoteChar = null;
      } else if (!inQuotes) {
        if (currentText) {
          content.push({
            type: 'text',
            text: currentText,
          });
          currentText = '';
        }
        quoteStart = i + 1;
        inQuotes = true;
        quoteChar = char;
      }
    } else if (!inQuotes) {
      currentText += char;
    }
  });

  // Add any remaining text after the loop
  if (currentText) {
    content.push({
      type: 'text',
      text: currentText,
    });
  }

  return {
    type: 'doc',
    content: [
      {
        type: 'paragraph',
        content,
      },
    ],
  };
}

export const customfieldAdvancedFormulaChipExtension = Node.create({
  name: 'formulaChip',

  group: 'inline',
  inline: true,
  selectable: true,
  atom: true,

  addAttributes() {
    return {
      type: {
        default: 'column',
      },
      value: {
        default: '',
      },
      color: {
        default: undefined,
      },
      icon: {
        default: undefined,
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'span[data-type="formula-chip"]',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['span', { ...HTMLAttributes, 'data-type': 'formula-chip' }];
  },

  addNodeView() {
    return VueNodeViewRenderer(CustomfieldAddOrEditAdvancedFormulaChip);
  },
});
