<script setup>
import { useI18n } from '@/util';
import { formulaFunctions } from './utils';

const props = defineProps({
  columns: {
    type: Array,
    required: true,
  },
  editor: {
    type: Object,
    required: true,
  },
});

const { t } = useI18n();

const functions = formulaFunctions.map((name) => ({
  name,
  type: 'function',
}));

function insertChip(item) {
  if (!props.editor) return;

  // Get the end position of the document
  const endPos = props.editor.state.doc.content.size;

  // Insert the chip at the end
  props.editor
    .chain()
    .setTextSelection(endPos)
    .insertContent({
      type: 'formulaChip',
      attrs: {
        type: item.type,
        value: item.name,
        color: item.type === 'column' ? '#E6F0FF' : undefined,
        icon: item.type === 'function' ? 'lsi-customfield-formula' : item.icon,
      },
    })
    .run();

  // For functions, add parentheses
  if (item.type === 'function') {
    props.editor.chain().insertContent(' ( )').run();
  }
}
</script>

<template>
  <div class="ml-auto w-40 overflow-y-auto">
    <div class="p-4">
      <LscAccordion>
        <LscAccordionItem name="columns" :title="t('Columns')" :modelValue="true" class="border-t-0">
          <div class="overflow-y-auto">
            <div class="space-y-1">
              <LscOptionsMenuItem
                v-for="column in columns"
                :key="column.name"
                :text="column.name"
                :prependIcon="column.icon"
                size="sm"
                @click="insertChip(column)"
              />
            </div>
          </div>
        </LscAccordionItem>
        <LscAccordionItem name="functions" :title="t('Functions')" :modelValue="true">
          <div class="overflow-y-auto">
            <div class="space-y-1">
              <LscOptionsMenuItem
                v-for="func in functions"
                :key="func.name"
                :text="func.name"
                prependIcon="lsi-customfield-formula"
                size="sm"
                @click="insertChip(func)"
              />
            </div>
          </div>
        </LscAccordionItem>
      </LscAccordion>
    </div>
  </div>
</template>
